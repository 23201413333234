import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import BlogPost from "./BlogPost.jsx";
import { AppContext } from "./AppProvider.jsx";
import AuthService from "./AuthService.jsx";
import withAuth from "./withAuth";
import WithLoading from "./WithLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const Auth = AuthService.getInstance();
const Blog = function (props) {
  const { state, dispatch } = useContext(AppContext);
  const [doc, setdoc] = useState({}); // '' is the initial state value
  const [loading, setloading] = useState(true);
  useEffect(() => {
    if (state.articles.length === 0) {
      Auth.fetch(`/blog`).then((data) => {
        dispatch({
          type: "FETCH_ARTICLES",
          data: data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
        });
        // dispatch({
        //   type: 'TOGGLE_TOAST',
        //   data: { open: true, type: `success`, message: `Articles fetched` },
        // })
        setloading(false);
      });
    } else {
      setloading(false);
    }
  }, []);
  const handleImageChange = (e) => {
    covertAntSetImage(e.target.files[0]);
  };
  const onPostEdit = (newDoc) => {
    setdoc(newDoc);
  };
  const covertAntSetImage = (data) => {
    data.error
      ? console.error(data.message)
      : data.arrayBuffer().then((buffer) => {
          const bufferFile = Buffer.from(buffer).toString("base64");
          setdoc({ ...doc, _attachments: { image: { data: bufferFile } } });
        });
  };

  useEffect(() => {
    let slug = "";
    doc && doc.title
      ? (slug = doc.title
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, ""))
      : (slug = "");

    setdoc({ ...doc, slug: slug });
  }, [doc.title]);

  const onPostDelete = (post) => {
    Auth.fetch(`/blog/${post._id}`, {
      method: "DELETE",
      body: JSON.stringify({ rev: post._rev }),
    }).then((data) => {
      dispatch({ type: "REMOVE_ARTICLE", data: data });
      dispatch({
        type: "TOGGLE_TOAST",
        data: {
          open: true,
          type: `success`,
          message: `Post ${post._id} deleted!`,
        },
      });
    });
  };

  const onPostSubmit = (event, publish) => {
    event.preventDefault();
    var data = new FormData();
    var imagedata = document.querySelector('input[type="file"]').files[0];
    let { ...postDoc } = doc;
    if (imagedata) {
      data.append("image", imagedata, imagedata.name);
    }
    const [...articles] = state.articles;
    if (!doc.publishedAt && publish) {
      postDoc.draft = false;
      postDoc.publishedAt = new Date().toISOString();
    } else if (!publish) {
      postDoc.draft = true;
    }
    if (doc._id) {
      postDoc._id = doc._id;
    }
    if (doc._rev) {
      postDoc._rev = doc._rev;
    } else {
    }
    var articleIndex = articles.findIndex((obj) => obj._id === doc._id);
    if (articleIndex !== -1) {
      articles.splice(articleIndex, 1);
    } else {
      doc.createdAt = new Date().toISOString();
      doc._id = postDoc.slug;
    }
    articles.unshift(doc);
    data.append("doc", JSON.stringify(postDoc));
    Auth.fetch(
      `/blog`,
      {
        method: "POST",
        body: data,
      },
      true
    )
      .then((res) => {
        if (res.error) {
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `danger`,
              message: `Error: ${JSON.stringify(res.message)}`,
            },
          });
        } else {
          dispatch({
            type: "FETCH_ARTICLES",
            data: articles,
          });
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `success`,
              message: doc._rev ? `Updated` : `Created`,
            },
          });
          setdoc({});
        }
      })
      .catch((e) => {
        console.debug(e);
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        });
      });
  };
  const posttemplate = (
    <div className="table-responsive ">
      <WithLoading loading={loading} waitfor={state.articles}>
        <table
          id="blogposts"
          className="py-5 table table-striped table-sm text-left "
        >
          <thead>
            <tr>
              <th scope="col">Created</th>
              <th scope="col">Status</th>
              <th scope="col">Title</th>
              <th colSpan="2" scope="col">
                Image
              </th>
            </tr>
          </thead>
          <tbody>
            {state.articles &&
              state.articles.map((doc) => (
                <BlogPost
                  key={doc._id}
                  onPostEdit={onPostEdit}
                  onPostDelete={onPostDelete}
                  doc={doc}
                />
              ))}
          </tbody>
        </table>
      </WithLoading>
    </div>
  );
  const onSaveDraft = (event) => {
    event.preventDefault();
    onPostSubmit(event, false);
  };

  const onPublish = (event) => {
    event.preventDefault();
    onPostSubmit(event, true);
  };

  return (
    <div className="container-fluid">
      <div className="text-center row">
        <div className="col-md-5">{posttemplate}</div>
        <div className="col-md-7">
          <form className="form-post mb-5">
            <div className="mb-3">
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="title" className="sr-only">
                      Header
                    </label>
                    <input
                      value={doc.title || ""}
                      onChange={(e) =>
                        setdoc({ ...doc, title: e.target.value })
                      }
                      type="text"
                      id="title"
                      className="form-control"
                      placeholder="Header"
                      autoFocus
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label htmlFor="subtitle" className="sr-only">
                      Subheader
                    </label>
                    <input
                      value={doc.subtitle || ""}
                      onChange={(e) =>
                        setdoc({ ...doc, subtitle: e.target.value })
                      }
                      type="text"
                      id="subtitle"
                      className="form-control"
                      placeholder="Subheader"
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3 d-flex align-items-center justify-content-start pr-0">
                  <span>https://sanktionsfrei.de/blog/</span>
                </div>
                <div className="pl-1 pr-0 col">
                  <label htmlFor="slug" className="sr-only">
                    Url
                  </label>
                  <input
                    value={doc.slug || ""}
                    onChange={(e) => setdoc({ ...doc, slug: e.target.value })}
                    type="text"
                    id="slug"
                    className="form-control pl-1"
                    placeholder="id"
                    autoFocus
                    required
                  />
                </div>
                <div className="col-1">
                  <button
                    type="button"
                    className="btn btn btn-primary btn-block"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://sanktionsfrei.de/blog/${doc.slug}`
                      );
                      dispatch({
                        type: "TOGGLE_TOAST",
                        data: {
                          open: true,
                          type: `success`,
                          message: `Link copied to clipboard!`,
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="teaser" className="sr-only">
                Teaser
              </label>
              <textarea
                value={doc.teaser || ""}
                onChange={(e) => setdoc({ ...doc, teaser: e.target.value })}
                id="teaser"
                className="form-control"
                placeholder="Teaser"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="content" className="sr-only">
                Content
              </label>
              <textarea
                value={doc.content || ""}
                onChange={(e) => setdoc({ ...doc, content: e.target.value })}
                id="content"
                className="form-control"
                placeholder="Content"
                required
              />
            </div>
            <div className="row mb-3">
              <div className="col-lg-6">
                <label htmlFor="content" className="sr-only">
                  File
                </label>
                <input
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                  className="form-control mb-3"
                  placeholder="Image"
                  required={doc._attachments ? false : true}
                />
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      onClick={onSaveDraft}
                      className="btn btn-lg btn-primary btn-block"
                    >
                      Speichern
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      onClick={onPublish}
                      className="btn btn-lg btn-success btn-block"
                    >
                      Veröffentlichen
                    </button>
                    {doc._id && (
                      <button
                        onClick={(e) => setdoc({})}
                        className="btn btn-lg btn-outline-danger mt-3 btn-block"
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {doc._attachments && (
                  <img
                    className="img-fluid"
                    src={`data:image/png;base64,${doc._attachments.image.data}`}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default withAuth(Blog);
