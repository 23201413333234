import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import BlogPost from "./BlogPost.jsx";
import { AppContext } from "./AppProvider.jsx";
import AuthService from "./AuthService.jsx";
import withAuth from "./withAuth.js";
import WithLoading from "./WithLoading.jsx";
require("dotenv").config();

const Auth = AuthService.getInstance();
const Images = function (props) {
  const { state, dispatch } = useContext(AppContext);
  const [doc, setdoc] = useState({}); // '' is the initial state value
  const [loading, setloading] = useState(true);
  useEffect(() => {
    if (state.articles.length === 0) {
      Auth.fetch(`/blog`).then((data) => {
        dispatch({
          type: "FETCH_ARTICLES",
          data: data.rows.sort(
            (a, b) => new Date(b.value.createdAt) - new Date(a.value.createdAt)
          ),
        });
        // dispatch({
        //   type: 'TOGGLE_TOAST',
        //   data: { open: true, type: `success`, message: `Articles fetched` },
        // })
        setloading(false);
      });
    } else {
      setloading(false);
    }
  }, []);
  const handleImageChange = (e) => {
    covertAntSetImage(e.target.files[0]);
  };
  const onPostEdit = (newDoc) => {
    setdoc(newDoc);
  };
  const covertAntSetImage = (data) => {
    data.error
      ? console.error(data.message)
      : data.arrayBuffer().then((buffer) => {
          const bufferFile = Buffer.from(buffer).toString("base64");
          setdoc({ ...doc, _attachments: { image: { data: bufferFile } } });
        });
  };
  const onPostDelete = (post) => {
    Auth.fetch(`/blog/${post._id}`, {
      method: "DELETE",
      body: JSON.stringify({ rev: post._rev }),
    }).then((data) => {
      dispatch({ type: "REMOVE_ARTICLE", data: data });
      dispatch({
        type: "TOGGLE_TOAST",
        data: {
          open: true,
          type: `success`,
          message: `Post ${post._id} deleted!`,
        },
      });
    });
  };
  const onPostSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput.files[0];
    formData.append("file", file, file.name);
    formData.append("requireSignedURLs", false);
    formData.append("metadata", JSON.stringify({ key: "value" }));

    console.log("formData", formData);

    const url = `https://api.cloudflare.com/client/v4/accounts/${process.env.REACT_APP_CLOUDFLARE_ACCOUNT_ID}/images/v1`;
    console.log("url", url);
    console.log("Bearer ", process.env.REACT_APP_CLOUDFLARE_BEARER_TOKEN);
    try {
      const response = await fetch(url, {
        method: "POST",
        header: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_BEARER_TOKEN}`,
          "Content-Type": "multipart/form-data",
          "Accept-Encoding": "gzip, deflate, br",
          Connection: "keep-alive",
        },
        body: formData,
        mode: "no-cors",
      });

      const result = await response;
      console.log("response", await response);
      if (result.success) {
        console.log("Image uploaded successfully:", result);
      } else {
        console.error("Image upload failed:", result.errors);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    /*       .then((res) => {
        if (res.error) {
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `danger`,
              message: `Error: ${JSON.stringify(res.message)}`,
            },
          });
        } else {
          dispatch({
            type: "FETCH_ARTICLES",
            data: articles,
          });
          dispatch({
            type: "TOGGLE_TOAST",
            data: {
              open: true,
              type: `success`,
              message: doc._rev ? `Updated` : `Created`,
            },
          });
          setdoc({});
        }
      })
      .catch((e) => {
        console.debug(e);
        dispatch({
          type: "TOGGLE_TOAST",
          data: {
            open: true,
            type: `danger`,
            message: `Error: ${JSON.stringify(e)}`,
          },
        });
      }); */
  };
  const posttemplate = (
    <div className="table-responsive ">
      <WithLoading loading={loading} waitfor={state.articles}>
        <table className="py-5 table table-striped table-sm text-left ">
          <thead>
            <tr>
              <th scope="col">Created</th>
              <th scope="col">Title</th>
              <th colSpan="2" scope="col">
                Image
              </th>
            </tr>
          </thead>
          <tbody>
            {state.articles &&
              state.articles.map((doc) => (
                <BlogPost
                  key={doc._id}
                  onPostEdit={onPostEdit}
                  onPostDelete={onPostDelete}
                  doc={doc}
                />
              ))}
          </tbody>
        </table>
      </WithLoading>
    </div>
  );
  return (
    <div className="container-fluid">
      <div className="text-center row">
        <div className="col-md-6">{posttemplate}</div>
        <div className="col-md-6">
          <form onSubmit={onPostSubmit} className="form-post mb-5">
            <div className="mb-3">
              <label htmlFor="content" className="sr-only">
                File
              </label>
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                className="form-control"
                placeholder="Image"
              />
            </div>
            {doc._attachments && (
              <img
                className="img-fluid"
                src={`data:image/png;base64,${doc._attachments.image.data}`}
              />
            )}
            <button type="submit" className="btn btn-lg btn-primary btn-block">
              Submit
            </button>
            {doc._id && (
              <button
                onClick={(e) => setdoc({})}
                className="btn btn-lg btn-outline-danger mt-3 btn-block"
              >
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default withAuth(Images);
