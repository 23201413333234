import React, { Component } from "react";
import Toast from "./Toast";
import Nav from "./Nav.jsx";
import "./App.css";
import Login from "./Login.jsx";
import Blog from "./Blog.jsx";
import Todos from "./Todos.jsx";
// import Settings from './Settings.jsx'
import Hartzbreakers from "./Hartzbreakers.jsx";
import BankDonations from "./BankDonations.jsx";
import Hartzbreaker from "./Hartzbreaker.jsx";
import RaffleParticipants from "./RaffleParticipants";
import RaffleDuplicates from "./RaffleDuplicates";
import ExamineDuplicates from "./ExamineDuplicates.jsx";
import RaffleWinners from "./RaffleWinners";
import RaffleWinner from "./RaffleWinner";
import RaffleBlocked from "./RaffleBlocked";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { AppProvider } from "./AppProvider.jsx";
import { createBrowserHistory } from "history";
import Images from "./Images.jsx";

const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <AppProvider>
        <Toast />
        <Router history={history}>
          <div className="h-100 pt-5 mt-5">
            <Nav />
            <Switch>
              <Route
                exact
                path="/signin"
                render={(props) => <Login {...props} />}
              />
              {/*<Route*/}
              {/*  exact*/}
              {/*  path="/settings"*/}
              {/*  render={() => (*/}
              {/*    <div className="pt-5">*/}
              {/*      <Settings/>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*/>*/}
              <Route
                exact
                path="/hartzbreaker/:id"
                render={(props) => (
                  <div className="">
                    <Hartzbreaker {...props} />
                  </div>
                )}
              />
              <Route
                exact
                path="/hartzbreaker"
                render={() => (
                  <div className="">
                    <Hartzbreakers />
                  </div>
                )}
              />
              <Route
                exact
                path="/verlosung/teilnehmer"
                render={() => (
                  <div className="">
                    <RaffleParticipants />
                  </div>
                )}
              />
              <Route
                exact
                path="/verlosung/duplikate"
                render={() => (
                  <div className="">
                    <RaffleDuplicates />
                  </div>
                )}
              />
              <Route
                exact
                path="/verlosung/duplikate-untersuchen"
                render={() => (
                  <div className="">
                    <ExamineDuplicates />
                  </div>
                )}
              />
              <Route
                exact
                path="/verlosung/gewinner"
                render={() => (
                  <div className="">
                    <RaffleWinners />
                  </div>
                )}
              />
              <Route
                exact
                path="/verlosung/geblockt"
                render={() => (
                  <div className="">
                    <RaffleBlocked />
                  </div>
                )}
              />
              <Route
                exact
                path="/verlosung/teilnehmer/:id"
                render={(props) => (
                  <div className="">
                    <RaffleWinner {...props} />
                  </div>
                )}
              />
              <Route
                exact
                path="/blog"
                render={() => <Blog logout={this.logout} />}
              />
              <Route
                exact
                path="/images"
                render={() => <Images logout={this.logout} />}
              />
              <Route
                exact
                path="/bankdonations"
                render={() => <BankDonations logout={this.logout} />}
              />
              <Route exact path="/" render={() => <Todos />} />
              <Route
                exact
                path="*"
                render={() => <Redirect to={{ pathname: "/signin" }} />}
              />
            </Switch>
          </div>
        </Router>
      </AppProvider>
    );
  }
}

export default App;
